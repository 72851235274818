<!--商品列表-->
<template>
  <div class="page-good-list">

    <Breadcrumb :data="breadList"></Breadcrumb>

    <div class="common-gap"></div>

    <div class="bottom-content">
      <div class="prod-group" :key="group.classifyId" v-for="group in goodGroup">
        <div class="common-title">
          <div class="_left">
            <span>{{ group.classifyName }} </span>
<!--            <el-image class="title-image" :src="group.imageUrl" fit="content"></el-image>-->
          </div>
          <div @click="toNext(group.hasNext,group.classifyId)" class="_right">
            Show All >>
          </div>
        </div>
        <el-row :gutter="30" class="good-list">
          <el-col :key="item.id" :span="6" v-for="item in group.productInfoVos">
            <GoodCard :item="item"></GoodCard>
          </el-col>
        </el-row>
      </div>

    </div>

  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import GoodCard from '@/components/GoodCard'

import { getAllProductClaasify, queryProductByClassify, showAllFunction } from '@/api/good'

export default {
  components: { Breadcrumb, GoodCard },
  data() {
    return {
      breadList: [],
      goodGroup: []
    }
  },
  methods: {
    toNext(hasNext, id) {
      if (hasNext) {
        this.$router.push({
          name: 'goodList',
          params: {
            id: id
          }
        })
      } else {
        this.$router.push({
          name: 'goodListAll',
          params: {
            id: id
          }
        })
      }

    },
    async queryData() {
      const res = await showAllFunction(this.$route.params.id)
      console.log('res', res)
      this.goodGroup = res.data

      const res2 = await this.$store.dispatch('GET_TREE_LIST_BY_CLASS_ID', this.$route.params.id)
      if (res2 && res2.nodes) {
        this.breadList = res2.nodes.map(item => {
          return {
            name: item.classifyName,
            to: {
              name: 'goodList',
              params: {
                id: item.id
              }
            }
          }
        })

        console.log('breadList', this.breadList)
      }

    }
  },
  async created() {
    console.log('检测到参数:', this.$route)
    await this.queryData()
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        //重新加载一次created或者mounted钩子中的渲染的数据
        this.queryData()
      }
    },
  }
}
</script>

<style lang="less" scoped>

.page-good-list{
  min-height: calc(100vh - 12.5rem);
  display: flex;
  flex-direction: column;
}

.prod-group {
  margin-bottom: 20px;
}

.bottom-content {
  background: var(--main-color-bg-gray);
  padding: 36px 75px 75px;
  flex: 1;
}
</style>

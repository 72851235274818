import service from '../utils/request';

// 基于类目查询 pageNum,pageSize,productClassifyId
export function queryProductByClassify(data) {
  return service({
    url: '/Cfront/productByClassify',
    method: 'POST',
    data: data
  })
}
// 基于类目查询 pageNum,pageSize,productClassifyId
export function productByClassifyV2(data) {
  return service({
    url: '/Cfront/productByClassifyV2',
    method: 'POST',
    data: data
  })
}

// 根据spu 查看商品详情
export function findProductBySPu(spu) {
  return service({
    url: '/Cfront/findProductBySPu',
    method: 'POST',
    params: {
      spu
    }
  })
}

// 根据spu 查看商品详情
export function queryTagDetail(id) {
  return service({
    url: '/Cfront/detail',
    method: 'POST',
    params: {
      id
    }
  })
}

// 基于Tag查询分页 pageNum,pageSize,tag
export function queryProductByTag(data) {
  return service({
    url: '/Cfront/productByTag',
    method: 'POST',
    data
  })
}

// 首页查所有类目关系
export function getAllProductClaasify() {
  return service({
    url: '/Cfront/getAllProductClaasify',
    method: 'POST'
  })
}

// 首页产品
export function firstPageEnter() {
  return service({
    url: '/Cfront/firstPageEnter',
    method: 'POST'
  })
}

// 查类目+产品列表
export function showAllFunction(parentClassifyId) {
  return service({
    url: '/Cfront/showAllFunction',
    method: 'POST',
    params: {
      parentClassifyId: parentClassifyId
    }
  })
}
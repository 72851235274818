<template>
  <div class="breadcrumb-content">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item replace :to="{ path: '/' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item class="item" :key="index" v-for="(item,index) in $props.data" :to="item.to">
        {{
          item.name
        }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>

</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    data: []
  }
}
</script>

<style scoped lang="less">

.breadcrumb-content {
  background: var(--main-color-bg-gray);

  height: 68px;
  display: flex;
  align-items: center;
  padding: 0 40px;

  /deep/.el-breadcrumb__item{
    display: flex;
    align-items: center;
  }

  /deep/.el-breadcrumb__inner{
    display: inline-block;
    max-width: 300px;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

</style>

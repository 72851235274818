<template>
  <div @click="toDetail" class="common-good-card" :class="type">

    <!--  列表样式  -->
    <template v-if="type==='card'">
      <el-image
        lazy
        class="image"
        :src="url"
        :fit="'contain'"></el-image>
      <div :title="item.productName" class="name text-ellipsis">{{ item.productName }}</div>
      <div :title="item.productSubTitle" class="sub-title text-ellipsis">{{ item.productSubTitle }}</div>

      <div class="tag">
        <template v-for="(tag,index) in item.tagName">
          <div v-if="index<2" @click.stop="toTag(index)" :key="tag" class="common-tag">
            {{ tag }}
          </div>
        </template>

      </div>

      <div class="price-info" v-if="!hasAdd">

        <template v-if="item.productInPromotion==='Y'">
          <div class="price">
            US${{ item.productPromotionPrice }}
          </div>

          <div class="org-price">
            US${{ item.productPrice }}
          </div>
        </template>
        <template v-else>
          <div class="price">
            US${{ item.productPrice }}
          </div>
        </template>

        <!--        <div class="price">-->
        <!--          ${{ item.price }}-->
        <!--        </div>-->

        <!--        <div class="org-price">-->
        <!--          ${{ item.orgPrice }}-->
        <!--        </div>-->

        <div @click.stop="addGood" class="carriage"></div>
      </div>

      <div class="input-row" @click.stop="stop" v-else>
        <el-input-number
          step-strictly
          style="width: 60%"
          :step="item.productMinNum"
          v-model="count"
          @change="handleChange"
          :min="0"
          :max="1000000000"
        ></el-input-number>

        <div @click.stop="addGood" class="carriage"></div>

      </div>
    </template>

    <!--  详情样式  -->
    <div class="detail-content" v-if="type==='detail'">
      <div class="detail-top">
        <div class="_left">
          <div class="name">{{ item.productName }}</div>
          <div class="sub-title">{{ item.productSubTitle }}</div>
        </div>
        <div class="_right">
          <div v-if="item.productTagId" class="tag">
            <template v-for="(tag,index) in item.tagName">
              <div @click="toTag(index)" :key="tag" class="common-tag">
                {{ tag }}
              </div>
            </template>

          </div>
        </div>
      </div>

      <div class="price-info">

        <template v-if="item.productInPromotion==='Y'">
          <div class="price">
            US${{ item.productPromotionPrice }}
          </div>

          <div class="org-price">
            US${{ item.productPrice }}
          </div>
        </template>
        <template v-else>
          <div class="price">
            US${{ item.productPrice }}
          </div>
        </template>

      </div>

      <div class="props-info">
        <div>MOQ: {{ item.productMinNum }}{{ item.productUnit }}</div>
        <div>Lead Time: {{ item.productCycle }}</div>
      </div>

      <div @click.stop="stop" class="opt-content">
        <!--        <div v-if="!CartItem" @click="addGood" class="carriage"></div>-->
        <el-button round size="medium" type="primary" v-if="!hasAdd" @click="addGood" class="cart-btn">Add to Cart
        </el-button>
        <el-input-number
          @click.stop="test"
          v-else
          step-strictly
          style="width: 50%"
          :step="item.productMinNum"
          v-model="count"
          @change="handleChange"
          :min="0"
          :max="1000000000"
        ></el-input-number>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "@/utils/resetMessage";

export default {
  name: "GoodCard",
  data: () => {
    return {
      hasAdd: false, // 这周目是否添加过，默认没有添加
      count: 0,
      url: ""
    };
  },
  created() {
    if (this.item.productImageId) {
      const list = this.item.productImageId.split(",");
      if (list && list.length > 0) {
        this.url = list[0];
      }
    } else {
      this.url = "";
    }
  },
  props: {
    type: {
      default: "card"
    },
    item: {
      default: () => {
        return {
          id: 123123, //主键
          productSpu: 123123, //PN
          productClassifyId: 123123, // 类目ID
          productName: "",
          productSubTitle: "",
          productDetail: "",
          "productInPromotion": "Y",
          "productPromotionPrice": null,
          productPrice: 0,
          orgPrice: 0,
          productUnit: "",
          productMinNum: 0,
          productCycle: "",
          url: "",
          tagName: "",
          productTagId: "",
          productCycleNum: ""
        };
      }
    }

  },
  computed: {
    CartItem() {
      // console.log(this.item.id, 'id')
      // console.log(this.item.id, this.$store.state.cartList)
      return this.$store.state.cartList.find((item) => Number(item.productId) === Number(this.item.id));
    },
    CartList() {
      return this.$store.state.cartList;
    }
  },
  methods: {
    stop() {
      return false;
    },
    toDetail() {
      if (this.type === "card") {
        this.$router.push({
          name: "detail",
          params: {
            id: this.item.productSpu
          }
        });
      }
    },
    // 添加购物车
    addGood() {

      //
      const token = localStorage.getItem("token");
      if (token) {

        //判断购物车历史有没有
        const tg = this.$store.state.cartList.find((item) => Number(item.productId) === Number(this.item.id));

        if (!tg) {
          this.$store.dispatch("addItem", {
            productId: this.item.id,
            amount: this.item.productMinNum
          });
        } else {
          // 有的话，增加一组
          this.$store.dispatch("modifyItem", {
            productId: this.item.id,
            amount: Number(tg.amount + this.item.productMinNum)
          });
        }


        // 显示添加成功modal
        this.$store.commit("SET_MODAL", true);
        this.hasAdd = true;
      } else {
        this.$router.push({
          name: "login"
        });
        Message({
          message: `Please login first`,
          type: "warning"
        });
      }


    },
    handleChange(value) {
      console.log("value", value);
      if (value === 0) {
        // 删除
        this.$store.dispatch("deleteItem", [this.item.id]);
        this.hasAdd = false;
      } else {
        // 修改
        this.$store.dispatch("modifyItem", {
          productId: this.item.id,
          amount: value
        });
      }


    },
    toTag(index) {
      console.log("tagId", index);
      this.$router.push({
        name: "goodListByTagAll",
        params: {
          id: this.item.productTagId[index]
        }
      });
    }
  },
  watch: {
    CartItem: {
      handler(newValue) {
        console.log("监听到卡片发现购物车对象变化:", newValue);
        if (newValue) {
          this.count = newValue.amount;
        } else {
          this.hasAdd = false;
        }
      },
      deep: true,
      immediate: true
    }
    // CartList: {
    //   handler(newValue) {
    //
    //     console.log('购物车列表发生变化：', newValue)
    //
    //   },
    //   deep: true,
    //   immediate: true
    // },
    // count(newValue) {
    //   console.log("value change");
    //   // this.$store.commit("CHANG_GOOD_COUNT", {
    //   //   good: this.item,
    //   //   num: newValue
    //   // });
    // }
  }
};
</script>

<style lang="less" scoped>

.common-good-card {
  cursor: pointer;
  padding: 25px;
  height: 600px;
  background: #FFFFFF;
  box-shadow: 0 5px 20px 0 rgba(214, 214, 214, 0.2);
  border-radius: 12px;

  &.detail {
    height: 100%;
  }

  .tag {
    min-height: 35px;
    cursor: pointer;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .name {
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    line-height: 43px;
  }

  .sub-title {
    font-size: 20px;
  }

  .image {
    width: 100%;
    height: 280px;
  }

  .price {
    font-style: italic;
    font-size: 30px;
    font-family: Poppins-Medium, ArialMT, serif;
    //font-family: Bangers, fantasy;
    font-weight: 400;
    color: var(--main-color-blue);
    line-height: 30px;
  }

  .org-price {
    //font-family: TypoPRO-DancingScri, serif;
    font-family: Poppins-Medium, ArialMT, serif;
    font-size: 20px;
    font-weight: normal;
    color: #868686;
    line-height: 43px;
    position: relative;

    &:before {
      transform: rotate(10deg) translateX(-10%);
      border-radius: 3px;
      border-top: 4px solid var(--main-color-red);
      position: absolute;
      content: '';
      display: block;
      left: 0;
      top: 50%;
      width: 120%;
      height: 3px;
    }
  }

  .carriage {
    cursor: pointer;
    background: url("~@/assets/images/chart-active.png") no-repeat center;
    width: 40px;
    height: 30px;
    background-size: contain;
  }

  .price-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.detail-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  ._left {
    flex: 0 0 70%;
  }

  ._right {
    flex: 1;
  }
}

.detail-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .name {
    font-size: 32px;
  }


  .props-info {
    margin-top: 50px;
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
  }

  padding: 0 30px;

  .opt-content {
    display: flex;
  }

  .price-info {
    align-items: flex-end;
    margin-top: 30px;
    justify-content: flex-start;

    .org-price {
      font-size: 38px;
    }

    .price {
      margin-right: 80px;
      font-size: 56px;
      line-height: 56px;
    }
  }
}

.common-tag {
  margin-right: 10px;
}

.cart-btn {
  background: #43328F;
  font-size: 20px;
}

.input-row {
  display: flex;
  align-items: center;
  //padding-right: 20px;
  justify-content: space-between;
}

</style>
